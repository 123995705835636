import axios from 'axios'
import {
    // actions
    SET_KEY_PROP_VALUE
   ,SET_DOC_KEY_PROP_VALUE
    // properties
   ,APP_URL
   ,UPLOADS
} from '../constants'
import {
    get_common
   ,post_common
   ,setKeyPropValue
} from '../actions/utils'
import { errorSnackbar, successSnackbar } from "./snackbar";
import { COLOR_BLUE, COLOR_GREEN, COLOR_LIGHT_GREY, COLOR_RED, COLOR_WHITE, COLOR_YELLOW } from "../../constants";
import { requestFile } from "./doc";
import {
    caption
} from './lang';


// TODO translation
export const statusList = [
    { id: "ALL",         name: "Все",                         color: COLOR_WHITE      }
   ,{ id: "NEW",         name: "Новая",                       color: COLOR_BLUE       }
   ,{ id: "IN_PROGRESS", name: "В работе",                    color: COLOR_WHITE      }
   ,{ id: "CANCELED",    name: "Отменено",                    color: COLOR_LIGHT_GREY }
   ,{ id: "ERROR",       name: "Завершено с ошибками",        color: COLOR_RED        }
   ,{ id: "OK",          name: "Успешно завершено",           color: COLOR_GREEN      }
   ,{ id: "WARNING",     name: "Завершено с предупреждением", color: COLOR_YELLOW     }
];

export const setUploadPropValue = ( name, value, key ) => (
    {
        type: UPLOADS + SET_KEY_PROP_VALUE
       ,payload: {
            name
           ,value
           ,key
        }
    }
);

export const setUploadDocPropValue = ( docId, name, value, key ) => (
    {
        type: UPLOADS + SET_DOC_KEY_PROP_VALUE
       ,payload: {
            docId
           ,name
           ,value
           ,key
        }
    }
);

// Скачать шаблон
export function loadMassDocGenerationTemplate( dispatch, docId ) {

    if( !Number.isInteger( docId ) ) return;
    
    const formData = new FormData();
    formData.append('docId', docId);

    requestFile( APP_URL + "/api/uploads/massDocGenerationTemplate", caption.massTemplate, "xls", dispatch, formData );
}

// Запустить обработку
export function startMassDocGeneration( dispatch, uploadId, docId ) {
    const formData = new FormData();
    formData.append('uploadId', uploadId);
    const config = {headers: {'content-type': 'multipart/form-data'}};
    post_common(
        APP_URL + '/api/uploads/startDocGeneration'
       ,formData
       ,config
       ,dispatch
       ,caption.massLoadError + ': '
       ,data => {
            if( data !== 'ERROR' ) {
                dispatch(setUploadDocPropValue(docId,  uploadId, data, "processingStatus"));
            } else {
                errorSnackbar( caption.massLoadError );
            }
       }
    );
}

// Скачать файл с результатами обработки
let cancelRequest;
export function loadMassDocGenerationFile ( dispatch, getState, file ) {

    const docId = getState().doc.docId;
    if( !Number.isInteger( docId ) ) return;

    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileName', file.name);
    formData.append('docId', docId);

    const config = {headers: {'content-type': 'multipart/form-data'}};

    const props = dispatch( ( dispatch, getState ) => getState() );
    const id = props.doc.docId;
    const uploads = props.uploads;
    const dateFrom = uploads.dateFrom;
    const dateTo = uploads.dateTo;
    const limit = uploads.limit === '' ? '' : Number.parseInt( uploads.limit, 10 );
    const tzOffset = uploads.tzOffset;
    const dateProcFrom = uploads.dateProcFrom;
    const dateProcTo = uploads.dateProcTo;
    const status = uploads.status;
    const fileName = uploads.fileName;
    const login = uploads.login;

    if( cancelRequest ) {
        cancelRequest.cancel();
    }
    cancelRequest = axios.CancelToken.source();

    post_common(
        APP_URL + '/api/uploads/loadDocGenerationFile'
       ,formData
       ,config
       ,dispatch
       ,caption.massResultError + ': '
       ,data => {
            if( data !== 'ERROR' ) {
                get_common(
                    APP_URL + '/api/uploads'
                   ,dispatch
                   ,caption.massResultError + ': '
                   ,data => {
                       dispatch( setKeyPropValue( UPLOADS, id, 'uploads', data ) );
                    }
                   ,f=>f
                   ,null
                   ,{
                        cancelToken: cancelRequest.token
                       ,params: { id, dateFrom, dateTo, limit, tzOffset, dateProcFrom, dateProcTo, status, fileName, login }
                    }
                   ,false
                   ,true
                );
                successSnackbar( caption.fileSaved );
            } else {
                errorSnackbar( caption.massResultError );
            }
       }
    );
}

// Изменить статус
export function changeUploadStatus ( dispatch, docId, uploadId, status ) {
    const formData = new FormData();
    formData.append('uploadId', uploadId);
    formData.append('status', status);
    const config = {headers: {'content-type': 'multipart/form-data'}};

    post_common(
        APP_URL + '/api/uploads/changeStatus'
       ,formData
       ,config
       ,dispatch
       ,caption.uploadStatusError + ': '
       ,data => {
            if( data !== 'ERROR' ) {
                dispatch(setUploadDocPropValue(docId,  uploadId, data, "processingStatus"));
            } else {
                errorSnackbar( caption.uploadStatusError );
            }
        }
    );
}
