// actions
export const ADD = 'ADD'
export const ADD_FIELD = 'ADD_FIELD'
export const CLEAR_UUIDS = 'CLEAR_UUIDS'
export const COPY_FIELD = 'COPY_FIELD'
export const INSERT_FIELDS = 'INSERT_FIELDS'
export const ADD_LI = 'ADD_LI'
export const ADD_LI_ALL = 'ADD_LI_ALL'
export const ADD_LIST_VALUES = 'ADD_LIST_VALUES'
export const COPY = 'COPY'
export const DELETE = 'DELETE'
export const DELETE_ALL = 'DELETE_ALL'
export const DELETE_FIELD = 'DELETE_FIELD'
export const DELETE_KEY_EMPTY_PROPS = 'DELETE_KEY_EMPTY_PROPS'
export const DELETE_LI = 'DELETE_LI'
export const DELETE_LI_ALL = 'DELETE_LI_ALL'
export const EDIT = 'EDIT'
export const SET_ALL_LOGINS = 'SET_ALL_LOGINS'
export const SET_CHANGE_FLAG = 'SET_CHANGE_FLAG'
export const SET_DOC_ID = 'SET_DOC_ID'
export const SET_FIELD = 'SET_FIELD'
export const SET_GLOBAL_LIST = 'SET_GLOBAL_LIST'
export const SET_ID = 'SET_ID'
export const SET_IS_LOADING = 'SET_IS_LOADING'
export const SET_ITEM = 'SET_ITEM'
export const SET_KEY_PROP_VALUE = 'SET_KEY_PROP_VALUE'
export const SET_LIST_BY_ID = 'SET_LIST_BY_ID'
export const SET_NAVBAR_ACTIVE = 'SET_NAVBAR_ACTIVE'
export const SET_PROP_VALUE = 'SET_PROP_VALUE'
export const SET_PROPS = 'SET_PROPS'
export const SET_STATE = 'SET_STATE'
export const SET_STATE_PROP_VALUE = 'SET_STATE_PROP_VALUE'
export const SET_STATE_PROP_MAP = 'SET_STATE_PROP_MAP'
export const SET_USER = 'SET_USER'
export const SET_TEMPLATE = 'SET_TEMPLATE'
export const SET_TEMPLATE_FORMAT = 'SET_TEMPLATE_FORMAT'
export const SET_DOC_KEY_PROP_VALUE = 'SET_DOC_KEY_PROP_VALUE'

// properties
export const APP_URL = ''
export const FORMAT = 'xxya_cdoc_format'
export const IPROC = 'xxya_cdoc_iproc'
export const ISNOPASS = 'xxya_cdoc_isnopass'
export const VENDOR_NOT_FOUND = 'xxya_cdoc_vendorNotFound';
export const TAG_PREFIX = 'xxya_cdoc_'
export const LOGIN = 'login'
export const REFINITIV = 'refinitiv'
export const SPARK = 'spark'
export const STARTREK_QUEUE_DEFAULT = 'LEGALTEST'
export const VENDOR = 'vendor'
export const VENDOR_ADDRESS = 'vendorAddress'
export const YA_ORGS = 'yaOrgs'
export const YA_BUSINESS_GROUPS = 'yaBGs'
export const STAFF_ALL_LIST_ITEM = { id: "ALL", name: "Все", tooltip: "Все", type: "ALL" }
export const IPROC_SUBTYPE_PROP = 'Доп. соглашение'
export const UPLOAD_TO_TICKET = "xxcdoc_SaveAndTicket_file"
// for protect selector
export const NORMAL = "normal"
export const GRAY = "gray"
export const HIDE = "hide"

// reducer names
export const DOC = 'DOC_'
export const EDIT_DIALOG = 'EDIT_DIALOG_'
export const GROUP = 'GROUP_'
export const GROUP_EDITOR = 'GROUP_EDITOR_'
export const HISTORY = 'HISTORY_'
export const MODAL = 'MODAL_'
export const SPIN = 'SPIN_'
export const USER = 'USER_'
export const FORM_TEMPLATE = 'FORM_TEMPLATE_'
export const TEMPLATE_EDITOR = 'TEMPLATE_EDITOR_'
export const ITEM_EDITOR = 'ITEM_EDITOR_'
export const LIST_EDITOR = 'LIST_EDITOR_'
export const EXT_EDITOR = 'EXT_EDITOR_'
export const ROLE = 'ROLE_'
export const VENDOR_DATA = 'VENDOR_DATA_'
export const CDOC_PROP = 'CDOC_PROP_'
export const UPLOADS = 'UPLOADS_'
export const YANDEX = 'YANDEX_'
export const BANK_DET_LIST_EDITOR = 'BANK_DET_LIST_EDITOR_'
export const SESSION = 'SESSION_'
export const TABLE = 'TABLE_'
