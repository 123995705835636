import React from 'react'
import classNames from 'classnames';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
//import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import {
    SESSION
} from "../../redux/constants";
import {
    setPropValue
} from '../../redux/actions/utils';
import {
    storeLang
} from '../../redux/actions/localStorage';
import {
    setGlobalLang
} from '../../redux/actions/lang';
import ru from '../../assets/ru.svg';
import en from '../../assets/gb.svg';


// Lang - выбор языка
// используется в Админке ( site: "admin" )
// и на странице Документ

const classes = {
    select: {
        fontSize: "20px"
       ,'&:hover': {
            color: '#ffb74d' // color_main
        }
       ,top: 0
    }
   ,doc: { color: '#c0c0c0' }
   ,header: { color: '#b3b0b0' }
   ,img: {
        position: "absolute"
       ,top: 22
       ,right: -14
       ,opacity: "1"
   }
};


const LangComponent = (  { classes, props } ) => {

    const dispatch = props.dispatch;
    const lang = props.lang ? props.lang : "ru";
    const site = props.site ? props.site : "header";
    
    const logo = lang === "en" ? en : ru;
        
    const styles = {
        doc: { position: "absolute", top: -11, right: 143 } // на форме Документ, не в шапке, чтобы в iframe тоже была возможность переключить язык
       ,header: { position: "fixed", top: -4, right: 150, zIndex: "1250" } // на других страницах - в шапке, чтобы при скролле не уезжало
    };
    
    // Tooltip глючит - не скрывается при открытии Select,
    // IconButton - жёлтое пятно, занимает слишком много места справа, из-за ширины Select,
    // div - чтобы выровнять пятно от IconButton
    return (
        //<Tooltip title="Language" arrow placement = "left" >
        
        <IconButton
            color="primary"
            style={ styles[ site ] }
        >

        <div style={{ width: "23px" }} >
        <img alt="" width="20" src={ logo } className={ classes.img } />
        <Select
          className={ classNames( classes.select, classes[ site ] ) }
          value = { lang }
          onChange = { e => {
              const lang = e.target.value;
              setGlobalLang( lang );
              dispatch( setPropValue( SESSION, 'lang', lang ) );
              storeLang( lang );
          }}
          disableUnderline
          IconComponent = "div" // скрыть стрелку
        >
          <MenuItem value={ "en" }>En</MenuItem>
          <MenuItem value={ "ru" }>Ru</MenuItem>
        </Select>
        </div>
        
        </IconButton>
        //</Tooltip>
    );

}

const LangStyled = withStyles( classes )( LangComponent );

const Lang = ( props ) => {
    return <LangStyled props = { props } />;
};

export default Lang;
