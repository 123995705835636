import axios from 'axios'
import * as moment from "moment";
import { connect } from 'react-redux'
import React, { Component } from 'react'
import { default as VUploadsForm } from '../components/UploadsForm/UploadsForm'
import {
// properties
    APP_URL
// reducer names
   ,UPLOADS
} from '../redux/constants'
import {
    docChange
   ,groupChange
   ,load
} from '../redux/actions/admin'
import {
    get_common
   ,setKeyPropValue
   ,setPropValue
   ,setMessage
} from '../redux/actions/utils'
import {
    changeUploadStatus
   ,loadMassDocGenerationFile
   ,loadMassDocGenerationTemplate
   ,startMassDocGeneration
} from '../redux/actions/uploads'
import {requestFile} from "../redux/actions/doc";

let needUpdate = true;

class UploadsForm extends Component {

    // вызывается при смене Шаблона и Статуса,
    // а при переходе на страницу - не вызывается
    componentDidUpdate( prevProps ) {
        // загрузить шаблон из базы, если его нет
        load( this.props, prevProps );
        if( this.props.doc.docId !== prevProps.doc.docId || this.props.uploads.status !== prevProps.uploads.status ) {
            setTimeout( () => this.props.onUpdateUploads(), 100 );
        }
    }

    componentDidMount() {
        if( needUpdate ) {
            setTimeout( () => this.props.onUpdateUploads(), 100 );
            //console.log("mount");
        }
    }

    componentWillUnmount() {
        if( this.props.modal.msg ) needUpdate = false;
    }

    render() {
        return (
             <VUploadsForm
                 { ...this.props }
             />
        )
    }
}


const mapStateToProps = ( { doc, uploads, role, spin, user, modal } ) => {
    return {
        doc
       ,uploads
       ,role
       ,spin
       ,user
       ,modal
    };
}


// Определяем есть ли у данного шаблона компонент "MassDocGeneration"
const  massDocGenerationComponentExists = ( dispatch, docId ) => {
    const props = dispatch((dispatch, getState) => getState());
    const massDocGenerationComponents = props.doc.docTemplates[docId].filter(i => i.type === "MassDocGeneration");
    return massDocGenerationComponents.length !== 0;
}

let cancelRequest;
const mapDispatchToProps = dispatch => ({
    dispatch
   ,onDocChange: ( e ) => docChange( dispatch, e )
   ,onGroupChange: ( e, oldGroupId ) => groupChange( dispatch, e, oldGroupId )
   ,onChange: ( e ) => {
        const target = e.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked :
                      target.type === 'file' && target.files[0] ? target.files[0].name :
                      target.value;
        dispatch( setPropValue( UPLOADS, name, value ) );
    }
   ,onNameValueChange: ( name, value ) => {
        dispatch( setPropValue( UPLOADS, name, value ) );
    }
    // форму убрать нельзя, Material UI компоненты привязываются к форме
   ,onFormSubmit: ( e ) => {
        e.preventDefault();
    }
   ,onDownload: ( uploadId, fileName, format ) => {
        if( !Number.isInteger( uploadId ) ) return;
        
        const formData = new FormData();
        formData.append('uploadId', uploadId);

        requestFile( APP_URL + "/api/uploads/downloadDocGenerationFile", fileName, format, dispatch, formData );

    }
   ,onUpdateUploads: () => {
        const props = dispatch( ( dispatch, getState ) => getState() );
        const id = props.doc.docId;
        const uploads = props.uploads;
        const dateFrom = uploads.dateFrom;
        const dateTo = uploads.dateTo;
        const limit = uploads.limit === '' ? '' : Number.parseInt( uploads.limit, 10 );
        const tzOffset = uploads.tzOffset;
        const dateProcFrom = uploads.dateProcFrom;
        const dateProcTo = uploads.dateProcTo;
        const status = uploads.status;
        const fileName = uploads.fileName;
        const login = uploads.login;

        if( !id ) return;
        if( limit !== '' && ( limit <= 0 || !Number.isInteger( limit ) ) ) return;
        if( !moment( dateFrom, "DD.MM.YYYY" ).isValid() ) return;
        if( !moment( dateTo,   "DD.MM.YYYY" ).isValid() ) return;
        if( dateProcFrom !== '' && !moment( dateProcFrom, "DD.MM.YYYY" ).isValid() ) return;
        if( dateProcTo   !== '' && !moment( dateProcTo,   "DD.MM.YYYY" ).isValid() ) return;

        if( cancelRequest ) {
            cancelRequest.cancel();
        }
        cancelRequest = axios.CancelToken.source();

        get_common(
            APP_URL + '/api/uploads'
           ,dispatch
           ,'Ошибка при получении данных о загрузках: '
           ,data => {
                dispatch( setKeyPropValue( UPLOADS, id, 'uploads', data ) );
            }
           ,f=>f
           ,null
           ,{
                cancelToken: cancelRequest.token
               ,params: { id, dateFrom, dateTo, limit, tzOffset, dateProcFrom, dateProcTo, status, fileName, login }
            }
           ,false
           ,true
        );
     }
   ,onLoadMassDocGenerationTemplate: (docId) => {
        if ( !docId ) return;
        massDocGenerationComponentExists(dispatch, docId)?
            loadMassDocGenerationTemplate(dispatch, docId)
            :
            dispatch(setMessage('Для данного шаблона не настроен компонент "Масс-формирование документов".'));
    }
   ,onLoad: ( e, docId ) => {
        if ( !docId ) return;
        const file = e.target.files[0];
        e.target.value = ''; // reset, to enable same file re-opening
        if( file )
        massDocGenerationComponentExists( dispatch, docId ) ?
            dispatch( ( dispatch, getState ) => {loadMassDocGenerationFile( dispatch, getState, file )})
            :
            dispatch( setMessage( 'Для данного шаблона не настроен компонент "Масс-формирование документов". Загрузка невозможна.' ) );

    }
   ,onCancel: ( docId, uploadId ) => {
        changeUploadStatus(dispatch, docId, uploadId, "CANCELED");
    }
   ,onStart: ( docId, uploadId ) => {
        startMassDocGeneration(dispatch, uploadId, docId);
    }
})

export default connect( mapStateToProps, mapDispatchToProps )( UploadsForm )
