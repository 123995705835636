import React from 'react'
// import { SelectValidator } from 'react-material-ui-form-validator'; // SelectValidator не уместился в iframe, заменил на TextValidator с атрибутом select
import { TextValidator } from 'react-material-ui-form-validator';
import MenuItem from "@material-ui/core/MenuItem";
import HiddenInput from "./HiddenInput";
import {
    GRAY
   ,HIDE
} from "../../redux/constants";

// Usage:
// props.list = [ "item", ... ] OR
// props.list = [ { id: "item", name: "Name" }, ... ] OR
// props.list = [
//  { subheader: true, id: "item", name: "Subheader" } // put id of the next item here, to handle click on subheader
// ,{ id: "item", name: "Name" }
// ,...
// ]

const Select = ( props ) => {
    
    // !visible - значит поля нет, его значение не определено,
    // protect = hide - поле есть, но скрыто от внешнего пользователя

    if( !props.visible && props.visible !== undefined ) return;
    const value = props.value === undefined || props.value === null ? '' : props.value;

    // protect для внешней формы
    if( props.isExternal && props.protect === HIDE )
    return (
        <HiddenInput
            name = { props.name }
            value = { value }
        />
    );

    const readonly = props.readonly || ( props.isExternal && props.protect === GRAY );
    
    return (
        <div className='padding'>
            <TextValidator
                select
                className={ props.className }
                label={ props.label }
                name={ props.name }
                value={ value }
                InputLabelProps={{ shrink: !!value || value === false }} // в том числе для checkbox defaultValue = false
                onChange={ props.onChange }
                validators={ props.validators }
                errorMessages={ props.errorMessages }
                helperText={ props.helperText }
                disabled = { readonly } // SelectValidator disabled поле попадает в submit form, ок
            >
                <MenuItem
                    key='-1'
                    value=''
                >
                    -
                </MenuItem>
            {
                props.list &&
                props.list.map( ( item, i ) => (
                    item && item.subheader ?
                    <optgroup // заголовок группы
                        key={i}
                        value={ item.id }
                        label={ item.name }
                        style={{ textAlign: "center" }}
                    />
                    :
                    //item ?
                    <MenuItem
                        style={{ whiteSpace: "pre-line" }}
                        key={i}
                        value={ item.id !== undefined ? item.id : item }
                    >
                        { item.name !== undefined ? item.name : item }
                    </MenuItem>
                    //: ''
                ))
            }
            </TextValidator>
        </div>
    );
}

export default Select
