import React from 'react'
import styles from "./Form.module.css"
import Card from "@material-ui/core/Card";
import HiddenInput from "./HiddenInput";
import InputWithSelect from "./InputWithSelect";
import Multiline from "./Multiline";
import { DateInput, onChangeDate } from "./DateInput";
import {
    hrPersonsFieldsList
   ,getHrPersonsDB
   ,getHrOnePersonDB
   ,setHrPersonsFields
   ,captions
} from '../../redux/actions/hrPersons';
import {
    GRAY
   ,HIDE
} from "../../redux/constants";


// особый случай, subpoena_receipt_date "Дата получения повестки" - это select, а не date,
// добавим части _dd, _mm, _yyyy
function subpoenaDateParts( name, value ) {
    const [ dd, mm, yyyy ] = value ? value.split(".") : [ "", "", "" ];
    return <>
        <HiddenInput
            name = { name + "_dd" }
            value = { dd }
        />
        <HiddenInput
            name = { name + "_mm" }
            value = { mm }
        />
        <HiddenInput
            name = { name + "_yyyy" }
            value = { yyyy }
        />
    </>;
}


const HrPersons = ( props ) => {
 
    if( !props.visible && props.visible !== undefined ) return;

    const lang = props.lang ? props.lang : "ru";
    const caption = captions[ lang ] ? captions[ lang ] : captions[ "ru" ];

    // protect для внешней формы
    const hidden = props.isExternal && props.protect === HIDE;
    const readonly = props.readonly || ( props.isExternal && props.protect === GRAY );
    const protect = props.protect;
    const isExternal = props.isExternal;
    const dispatch = props.dispatch;

    const suffix = props.suffix === undefined || props.suffix === null ? '' : props.suffix;

    const specialFields = {};
    
    // Выпадающий список с полем ввода для Логина
    let tag = 'hr_login' + suffix;
    specialFields.hr_login =
        <InputWithSelect
            className = { props.className }
            key = { tag }
            name = { tag }
            label = { caption.hr_login }
            value = { props.state[ tag ] }
            validators = { props.validators }
            errorMessages = { props.errorMessages }
            list = { props.selectList }
            onNameValueChange = { props.onNameValueChange }
            onInputChange = { value => getHrPersonsDB( dispatch, value ) } // чтение справочника из базы
            onSelectChange = {
                value => {
                 
                    setHrPersonsFields(
                        dispatch
                       ,value
                       ,undefined
                       ,undefined
                       ,undefined
                       ,undefined
                       ,suffix
                    );
                    
                    if( !value ) return;
                    getHrOnePersonDB( dispatch, value, props.limitToBusinessGroups, suffix ); // чтение всех данных по одному лицу
                }
            } // зависимые поля заполняются значениями из справочника
            // readonly = { readonly } // сюда не передаём, логин можно выбирать
            protect = { protect }
            isExternal = { isExternal }
        />;

    // Выпадающий список с полем ввода для Бизнес-группы
    tag = 'hr_business_group_name' + suffix;
    specialFields.hr_business_group_name =
        <InputWithSelect
            className = { props.className }
            key = { tag }
            name = { tag }
            label = { caption.hr_business_group_name }
            value = { props.state[ tag ] }
            validators = { props.validators }
            errorMessages = { props.errorMessages }
            list = { props.state[ 'businessGroupList' + suffix ] }
            onNameValueChange = { props.onNameValueChange }
            onInputChange = { props.onInputChange }
            onSelectChange = {
                value => setHrPersonsFields(
                    dispatch
                   ,props.state[ 'hr_login' + suffix ]
                   ,value
                   ,undefined
                   ,undefined
                   ,undefined
                   ,suffix
                )
            } // зависимые поля заполняются значениями из справочника
            // readonly = { readonly } // сюда не передаём, можно выбирать
            protect = { protect }
            isExternal = { isExternal }
        />;
        
    // Выпадающий список с полем ввода для Номера назначения
    tag = 'hr_assignment_number' + suffix;
    specialFields.hr_assignment_number =
        <InputWithSelect
            className = { props.className }
            key = { tag }
            name = { tag }
            label = { caption.hr_assignment_number }
            value = { props.state[ tag ] }
            validators = { props.validators }
            errorMessages = { props.errorMessages }
            list = { props.state[ 'assignmentsList' + suffix ] }
            onNameValueChange = { props.onNameValueChange }
            onInputChange = { props.onInputChange }
            onSelectChange = {
                value => setHrPersonsFields(
                    dispatch
                   ,props.state[ 'hr_login' + suffix ]
                   ,props.state[ 'hr_business_group_name' + suffix ]
                   ,value
                   ,undefined
                   ,undefined
                   ,suffix
                )
            }
            protect = { protect }
            isExternal = { isExternal }
        />;

    // Выпадающий список с полем ввода для Номера назначения
    tag = 'hr_loan_number' + suffix;
    specialFields.hr_loan_number =
        <InputWithSelect
            className = { props.className }
            key = { tag }
            name = { tag }
            label = { caption.hr_loan_number }
            value = { props.state[ tag ] }
            validators = { props.validators }
            errorMessages = { props.errorMessages }
            list = { props.state[ 'loanList' + suffix ] }
            onNameValueChange = { props.onNameValueChange }
            onInputChange = { props.onInputChange }
            onSelectChange = {
                value => setHrPersonsFields(
                    dispatch
                   ,props.state[ 'hr_login' + suffix ]
                   ,props.state[ 'hr_business_group_name' + suffix ]
                   ,props.state[ 'hr_assignment_number' + suffix ]
                   ,value
                   ,undefined
                   ,suffix
                )
            }
            protect = { protect }
            isExternal = { isExternal }
        />;

    tag = 'subpoena_receipt_date' + suffix;
    const dateParts = subpoenaDateParts( tag, props.state[ tag ] );
    specialFields.subpoena_receipt_date =
        <InputWithSelect
            className = { props.className }
            key = { tag }
            name = { tag }
            label = { caption.subpoena_receipt_date }
            value = { props.state[ tag ] }
            validators = { props.validators }
            errorMessages = { props.errorMessages }
            list = { props.state[ 'subpoenaList' + suffix ] }
            onNameValueChange = { ( name, value ) => ( value ) => onChangeDate( tag, props.onNameValueChange ) } // было props.onNameValueChange
            onInputChange = { props.onInputChange } // undefined, не используется
            onSelectChange = {
                value => setHrPersonsFields(
                    dispatch
                   ,props.state[ 'hr_login' + suffix ]
                   ,props.state[ 'hr_business_group_name' + suffix ]
                   ,props.state[ 'hr_assignment_number' + suffix ]
                   ,props.state[ 'hr_loan_number' + suffix ]
                   ,value
                   ,suffix
                )
            }
            protect = { protect }
            isExternal = { isExternal }
        />;
    
    const fieldsList = Array.isArray( props.fieldsList ) ? props.fieldsList : [];

    const content = hrPersonsFieldsList.map( i =>
        {
            const id = i.id;
            const tag = id + suffix;
            let item = undefined;
            
            // поля с особым поведением
            if([ 'hr_login'
                ,'hr_business_group_name'
                ,'hr_assignment_number'
                ,'hr_loan_number'
                ,'subpoena_receipt_date'
               ].includes( id )
            ) item = specialFields[ id ];
            
            if( item !== undefined )
                if( fieldsList.includes( id ) )
                    return item;
                else
                    return <HiddenInput key = { tag } name = { tag } value = { props.state[ tag ] } />;
            
            // остальные поля
            if( fieldsList.includes( id ) )
                return id.includes( "date" ) && ( id !== 'subpoena_appearence_date_time' ) ? // subpoena_appearence_date_time содержит дату и время, "13.10.2022 19:00:00"
                <DateInput
                    key = { tag }
                    className = { props.className }
                    label = { caption[ id ] }
                    name = { tag }
                    value = { props.state[ tag ] }
                    value_dd = { props.state[ tag + "_dd" ] }
                    value_mm = { props.state[ tag + "_mm" ] }
                    value_yyyy = { props.state[ tag + "_yyyy" ] }
                    onChange = { onChangeDate( tag, props.onNameValueChange ) }
                    validators = { props.validators }
                    errorMessages = { props.errorMessages }
                    readonly = { readonly }
                    protect = { protect }
                    isExternal = { isExternal }
                    isDefaultDate = { false } // не устанавливать текущую дату по умолчанию
                />
                :
                <Multiline
                    key = { tag }
                    className = { props.className }
                    label = { caption[ id ] }
                    name = { tag }
                    value = { props.state[ tag ] }
                    onChange = { props.onChange }
                    validators = { props.validators }
                    errorMessages = { props.errorMessages }
                    readonly = { readonly }
                    protect = { protect }
                    isExternal = { isExternal }
                />;
            else
                // невидимые поля должны попасть в form submit
                return <HiddenInput key = { tag } name = { tag } value = { props.state[ tag ] } />;
        }
    );
    
    if( hidden ) return content;
    
    
    return (
        <Card className={ styles.xxComponentCard }>
        <div className={ styles.xxComponentCardLabel }>{ props.label }</div>
        <div className={ styles.xxComponentCardContent }>
            { content }
            { dateParts }
        </div>
        </Card>
    );
}

export default HrPersons
