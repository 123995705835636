import {
// actions
    ADD
   ,ADD_FIELD
   ,CLEAR_UUIDS
   ,COPY_FIELD
   ,INSERT_FIELDS
   ,COPY
   ,DELETE
   ,DELETE_ALL
   ,DELETE_FIELD
   ,DELETE_KEY_EMPTY_PROPS
   ,EDIT
   ,SET_ALL_LOGINS
   ,SET_CHANGE_FLAG
   ,SET_DOC_ID
   ,SET_ID
   ,SET_GLOBAL_LIST
   ,SET_KEY_PROP_VALUE
   ,SET_PROP_VALUE
   ,SET_PROPS
   ,SET_STATE
   ,SET_STATE_PROP_VALUE
   ,SET_STATE_PROP_MAP
   ,SET_TEMPLATE
   ,SET_TEMPLATE_FORMAT
   ,SET_ITEM
// reducer names
   ,DOC
   ,FORM_TEMPLATE
   ,TEMPLATE_EDITOR
   ,GROUP
   ,GROUP_EDITOR
   ,TABLE
   ,VENDOR_DATA
   ,YANDEX
} from './../constants'
import { docPartTypes, createDocParts } from '../../components/DocParts/DocParts';
import docTemplate from './docTemplate';
import docState from './docState';
import groupEditor from './groupEditor';
import table from './table';
import templateEditor from './templateEditor';
import vendorState from './vendorState';
import yandex from './yandex';
//import { templateNda } from './templateNda'; // [{},...]

const docParts = createDocParts();

const initialState = {
    docParts // компоненты для форм
   ,globalLists: { // списки, которые могут использоваться во всех шаблонах
        docPartTypes // список компонентов
       ,yaBGs: [] // Список бизнес-групп Яндекс
    }
   ,groupNames: [] // список групп шаблонов [ { id: 1, name: 'Общая' } ]
   ,groupAdminList: [] // список групп шаблонов с правами админа, TODO может быть это лишнее копирование groupNames
   ,groupId: ''
   ,docNames: [] // список шаблонов [ { id: 1, name: 'Соглашение о неразглашении конфиденциальной информации' } ]
   ,docAdminList: [] // список шаблонов с правами админа, TODO может быть это лишнее копирование docNames
   ,docId: ''
   //,docCode: '' // Код шаблона, краткое наименование
   ,tmpIdSequence: 0 // убывающий счётчик временных id, потом из базы должен вернуться реальный id
   ,yaOrgs: [] // Список компаний группы Яндекс
   ,hrPersons: [] // HR Справочник cотрудников
   ,login: '' // заполняется при старте
   
   // состояние формы,
   // уникальный id будет получен из базы
   ,docStates: {} // { 1: { состояние формы } }
   
   // шаблоны загружаются из базы
   ,docTemplates: {} // { 1: templateNda }

   ,templateEditor: { open: false } // редактор имени шаблона
   ,groupEditor: { open: false } // редактор имени группы
   ,showPassword: false
   
    // Список Суперпользователей
    // для всех, чтобы показать список уполномоченных, если нет доступа к шаблону
   ,superuserList: []
   
   ,startrekFieldsList: [] // Список полей Startrek
   ,tmplItems: [] // Список полей шаблона

   ,vendorStates: {} // { id: {данные контрагента}}
   ,expiredUrl: false // внешняя ссылка неактивна
   
   ,showPreview: false
}

export default (state = initialState, action) => {
    switch (action.type) {

        case DOC + SET_PROP_VALUE:
            const name = action.payload.name
            const value = action.payload.value
            return {
                ...state,
                [name]: value
            };

        case DOC + SET_STATE_PROP_VALUE:
        case DOC + SET_STATE_PROP_MAP:
            return {
                ...state,
                docStates: {
                    ...state.docStates
                   ,[ state.docId ]: docState( state.docStates[ state.docId ], action )
                }
            };

        case DOC + SET_GLOBAL_LIST:
            return {
                ...state
               ,globalLists: {
                    ...state.globalLists
                   ,[ action.payload.name ]: action.payload.value
                }
            };

        case TABLE + ADD:
        case TABLE + DELETE:
        case TABLE + DELETE_ALL:
        case TABLE + SET_STATE_PROP_VALUE:
            return {
                ...state,
                docStates: {
                    ...state.docStates
                   ,[ state.docId ]: table( state.docStates[ state.docId ], action )
                }
            };
            
        // usage: при загрузке формы
        // прежнее состояние будет прочитано из localStorage
        case DOC + SET_STATE:
            return {
                ...state,
                docStates: {
                    ...state.docStates
                   ,[ state.docId ]: {
                        ...action.payload.value
                        // для внешнего пользователя - взять логин из данных, иначе - оставить текущий логин
                        // TODO получается, логин утекает вовне
                       ,login: state.login === "xx_cdoc_ext_user" ? action.payload.value.login : state.login // TODO login: state.login
                    }
                }
            };
            
        case DOC + SET_ALL_LOGINS:
            Object.keys( state.docStates ).forEach( key => {
                state.docStates[ key ].login = state.login;
            });
            return state;

        // uuids всегда должны быть новыми,
        // поэтому очищаем после каждой отправки формы
        case DOC + CLEAR_UUIDS:
            state.docTemplates[ state.docId ].filter( i => i.type === "Uuid" ).forEach( i => {
                state.docStates[ state.docId ][ i.name ] = undefined;
            });
            return state;

        // для Preview нужно установить формат шаблона
        // при успешной загрузке файла шаблона в Админке
        case FORM_TEMPLATE + SET_TEMPLATE_FORMAT:
            const docIdNum = state.docId;
            const docName = state.docNames.find( i => i.id === docIdNum );
            const format = action.payload.value;
            docName.format = format;
            return { ...state }; // new state object
            
        case FORM_TEMPLATE + SET_TEMPLATE:
            const docId = state.docId;
            const doc = state.docNames.find( i => i.id === docId );
            if( doc === undefined ) return state;
            const template = action.payload.value;
            return {
                ...state
               ,docTemplates: {
                    ...state.docTemplates
                   ,[ docId ]: template
                }
                // сначала передаю пустую форму [] для инициализации,
                // потом жду ответа от БД
               ,docStates: template.length > 0 ? state.docStates : {
                    ...state.docStates
                   ,[ docId ]: {
                        login: state.login
                    }
                }
            };

        case FORM_TEMPLATE + SET_KEY_PROP_VALUE:
        case FORM_TEMPLATE + DELETE_KEY_EMPTY_PROPS:
        case FORM_TEMPLATE + ADD_FIELD:
        case FORM_TEMPLATE + COPY_FIELD:
        case FORM_TEMPLATE + INSERT_FIELDS:
        case FORM_TEMPLATE + DELETE_FIELD:
        case FORM_TEMPLATE + SET_ITEM:
            const docIdNow = state.docId;
            return {
                ...state
               ,docTemplates: {
                    ...state.docTemplates
                   ,[ docIdNow ]: docTemplate( state.docTemplates[ docIdNow ], action )
                }
                // флаг сохранения, если форма изменилась - isChanged = true
                // оптимизация - можно вынести текущий isChanged на уровень state, если isChanged уже true - не менять,
                // но получается запутанно, не делаю
               ,docAdminList: state.docAdminList.map( i => i.id === docIdNow ? { ...i, isChanged: true } : i )
            };
            
        case GROUP + ADD:
        case GROUP + DELETE:
        case GROUP + EDIT:
        case GROUP + SET_ID:
        case GROUP_EDITOR + SET_PROPS:
        case GROUP_EDITOR + SET_PROP_VALUE:
            return groupEditor( state, action );
        
        case FORM_TEMPLATE + ADD:
        case FORM_TEMPLATE + COPY:
        case FORM_TEMPLATE + DELETE:
        case FORM_TEMPLATE + EDIT:
        case FORM_TEMPLATE + SET_CHANGE_FLAG:
        case FORM_TEMPLATE + SET_DOC_ID:
        case TEMPLATE_EDITOR + SET_PROPS:
        case TEMPLATE_EDITOR + SET_PROP_VALUE:
            return templateEditor( state, action );

        case VENDOR_DATA + SET_STATE_PROP_VALUE:
            return {
                ...state,
                vendorStates: vendorState( state, action )
            };

        case YANDEX + SET_STATE_PROP_VALUE:
            return {
                ...state,
                yaOrgs: yandex( state, action )
            };
            
        default:
            return state;
    }
}
