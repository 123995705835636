import axios from 'axios';
import {
    setDocStatePropMap
   ,setDocStatePropValue
} from './doc';
import {
    getOptionsListRecords
   ,getOptionsRecordProp
   ,copyProps
   ,dateParts
} from './optionsList';
import {
   // properties
    APP_URL
   // reducer names
   ,DOC
} from '../constants';
import {
    get_common
   ,makeUnique
   ,setPropValue
} from './utils';
import {
    caption
} from './lang';


// id полей используются в шаблонах Word, менять нельзя
export const captions = {
    "ru": {
        hr_login: "Логин"
       ,hr_business_group_name: "Бизнес-группа"
       ,hr_last_name: "Фамилия"
       ,hr_first_name: "Имя"
       ,hr_middle_names: "Отчество"
       ,hr_fio: "ФИО"
       ,hr_familyio: "Фамилия И.О."
       ,hr_iofamily: "И.О. Фамилия"
       ,hr_birth_date: "Дата рождения"
       ,hr_job_name: "Должность"
       ,hr_inn: "ИНН"
       ,hr_snils: "СНИЛС"
       ,hr_citizenship: "Гражданство"
       ,hr_address: "Адрес личный"
       ,hr_address_alt: "Адрес альтернативный"
       ,hr_email: "Email личный"
       ,hr_phone: "Телефон"
       ,id_doc_type: "Вид документа"
       ,id_doc_series: "Серия"
       ,id_doc_number: "Номер"
       ,id_doc_date_from: "Дата выдачи"
       ,id_doc_issued_by: "Кем выдан"
       ,id_doc_date_to: "Действителен до"
       ,id_doc_dep_code: "Код подразделения"
       ,id_doc_full: "Паспортные данные"
       ,hr_name_en: "ФИО латиницей"
       ,hr_fio_gen: "ФИО в родительном падеже"
       ,hr_fio_dat: "ФИО в дательном падеже"
       ,hr_fio_acc: "ФИО в винительном падеже"
       ,hr_fio_inst: "ФИО в творительном падеже"
       ,hr_fio_prepos: "ФИО в предложном падеже"
       ,hr_address_r: "Адрес регистрации"
       ,hr_address_c: "Адрес проживания"
       ,hr_assignment_number: "Номер назначения"
       ,hr_contract_number: "Номер трудового договора"
       ,hr_contract_date: "Дата трудового договора"
       ,hr_employee_number: "Табельный номер"
       ,hr_assg_job_name: "Должность (назначение)"
       ,hr_assg_job_name_gen: "Должность (назначение) в родительном падеже"
       ,hr_assg_job_name_dat: "Должность (назначение) в дательном падеже"
       ,hr_assg_job_name_acc: "Должность (назначение) в винительном падеже"
       ,hr_assg_job_name_inst: "Должность (назначение) в творительном падеже"
       ,hr_assg_job_name_prepos: "Должность (назначение) в предложном падеже"
       ,hr_tax_unit_full_name: "ЮЛ полное наименование"
       ,hr_tax_unit_short_name: "ЮЛ краткое наименование"
       ,hr_general_visa_familyio: "Ген. дир. Фамилия И.О."
       ,hr_general_visa_iofamily: "Ген. дир. И.О. Фамилия"
       ,hr_general_visa_fio: "Ген. дир. ФИО"
       ,hr_general_visa_gen: "Ген. дир. ФИО в родительном падеже"
       ,hr_general_visa_dat: "Ген. дир. ФИО в дательном падеже"
       ,hr_general_visa_acc: "Ген. дир. ФИО в винительном падеже"
       ,hr_general_visa_inst: "Ген. дир. ФИО в творительном падеже"
       ,hr_general_visa_prepos: "Ген. дир. ФИО в предложном падеже"
       ,hr_general_visa_job: "Ген. дир. должность"
       ,hr_general_visa_job_gen: "Ген. дир. должность в родительном падеже"
       ,hr_general_visa_job_dat: "Ген. дир. должность в дательном падеже"
       ,hr_general_visa_job_acc: "Ген. дир. должность в винительном падеже"
       ,hr_general_visa_job_inst: "Ген. дир. должность в творительном падеже"
       ,hr_general_visa_job_prepos: "Ген. дир. должность в предложном падеже"
       ,hr_general_visa_snils: "Ген. дир. СНИЛС"
       ,hr_dept_full_name: "Подразделение полное наименование"
       ,hr_dept_short_name: "Подразделение краткое наименование"
       ,hr_dept_manag_name: "Подразделение системное наименование"
       ,hr_sex: "Пол"
       ,hr_reg_city: "Город регистрации"
       ,hr_loan_number: "Номер займа"
       ,hr_loan_purpose: "Цель займа"
       ,hr_loan_date: "Дата займа"
       ,hr_town_of_birth: "Город рождения"
       ,hr_region_of_birth: "Область рождения"
       ,hr_country_of_birth: "Страна рождения"
       ,hr_reg_country: "Адрес: Страна"
       ,hr_reg_region: "Адрес: Регион"
       ,hr_reg_area: "Адрес: Район"
       ,hr_reg_town: "Адрес: Город"
       ,hr_reg_settlement: "Адрес: Населенный пункт"
       ,hr_reg_street: "Адрес: Улица"
       ,hr_reg_house: "Адрес: Дом"
       ,hr_reg_building: "Адрес: Корпус"
       ,hr_reg_apartment: "Адрес: Квартира"
       ,hr_reg_postal_code: "Адрес: Индекс"
       ,hr_reg_combination: "Адрес: Улица, дом, корпус, квартира"
       ,hr_division: "Кадровое подразделение"
       ,hr_it_num: "Номер ИТ-аккредитации"
       ,hr_it_start_date: "Дата ИТ-аккредитации"
       ,subpoena_receipt_date: "Дата получения повестки"
       ,subpoena_seria_num: "Серия и номер повестки"
       ,subpoena_appearence_date_time: "Дата и время явки в военкомат"
       ,subpoena_military_office: "Наименование военкомата"
       ,subpoena_military_office_address: "Адрес военкомата"
       ,subpoena_type: "Тип повестки"
       ,subpoena_reason: "Причина вызова в военкомат"
    }
   ,"en": {
        hr_login: "Login"
       ,hr_business_group_name: "Business group"
       ,hr_last_name: "Last name"
       ,hr_first_name: "First name"
       ,hr_middle_names: "Middle names"
       ,hr_fio: "Full name"
       ,hr_familyio: "Last name F.M."
       ,hr_iofamily: "F.M. Last name"
       ,hr_birth_date: "Date of birth"
       ,hr_job_name: "Position"
       ,hr_inn: "INN"
       ,hr_snils: "Insurance Number"
       ,hr_citizenship: "Citizenship"
       ,hr_address: "Personal address"
       ,hr_address_alt: "Alternative address"
       ,hr_email: "Personal email"
       ,hr_phone: "Phone"
       ,id_doc_type: "Type of document"
       ,id_doc_series: "Series"
       ,id_doc_number: "Number"
       ,id_doc_date_from: "Date of issue"
       ,id_doc_issued_by: "Issued by"
       ,id_doc_date_to: "Valid until"
       ,id_doc_dep_code: "Department code"
       ,id_doc_full: "Passport data"
       ,hr_name_en: "Full name in Latin"
       ,hr_fio_gen: "Full name in Genitive"
       ,hr_fio_dat: "Full name in Dative"
       ,hr_fio_acc: "Full name in Accusative"
       ,hr_fio_inst: "Full name in Instrumental"
       ,hr_fio_prepos: "Full name in Prepositional"
       ,hr_address_r: "Registration address"
       ,hr_address_c: "Residential address"
       ,hr_assignment_number: "Assignment number"
       ,hr_contract_number: "Contract number"
       ,hr_contract_date: "Contract date"
       ,hr_employee_number: "Employee number"
       ,hr_assg_job_name: "Position (assignment)"
       ,hr_assg_job_name_gen: "Position (assignment) in Genitive"
       ,hr_assg_job_name_dat: "Position (assignment) in Dative"
       ,hr_assg_job_name_acc: "Position (assignment) in Accusative"
       ,hr_assg_job_name_inst: "Position (assignment) in Instrumental"
       ,hr_assg_job_name_prepos: "Position (assignment) in Prepositional"
       ,hr_tax_unit_full_name: "Tax Unit full name"
       ,hr_tax_unit_short_name: "Tax Unit short name"
       ,hr_general_visa_familyio: "General manager Last name F.M."
       ,hr_general_visa_iofamily: "General manager F.M. Last name"
       ,hr_general_visa_fio: "General manager Full name"
       ,hr_general_visa_gen: "General manager Full name in Genitive"
       ,hr_general_visa_dat: "General manager Full name in Dative"
       ,hr_general_visa_acc: "General manager Full name in Accusative"
       ,hr_general_visa_inst: "General manager Full name in Instrumental"
       ,hr_general_visa_prepos: "General manager Full name in Prepositional"
       ,hr_general_visa_job: "General manager Position"
       ,hr_general_visa_job_gen: "General manager Position in Genitive"
       ,hr_general_visa_job_dat: "General manager Position in Dative"
       ,hr_general_visa_job_acc: "General manager Position in Accusative"
       ,hr_general_visa_job_inst: "General manager Position in Instrumental"
       ,hr_general_visa_job_prepos: "General manager Position in Prepositional"
       ,hr_general_visa_snils: "General manager insurance number"
       ,hr_dept_full_name: "Department full name"
       ,hr_dept_short_name: "Department short name"
       ,hr_dept_manag_name: "Department system name"
       ,hr_sex: "Gender"
       ,hr_reg_city: "City of registration"
       ,hr_loan_number: "Loan number"
       ,hr_loan_purpose: "Loan purpose"
       ,hr_loan_date: "Loan date"
       ,hr_town_of_birth: "Town of birth"
       ,hr_region_of_birth: "Region of birth"
       ,hr_country_of_birth: "Country of birth"
       ,hr_reg_country: "Address: Country"
       ,hr_reg_region: "Address: Region"
       ,hr_reg_area: "Address: Area"
       ,hr_reg_town: "Address: Town"
       ,hr_reg_settlement: "Address: Settlement"
       ,hr_reg_street: "Address: Street"
       ,hr_reg_house: "Address: House"
       ,hr_reg_building: "Address: Building"
       ,hr_reg_apartment: "Address: Apartment"
       ,hr_reg_postal_code: "Address: Postal code"
       ,hr_reg_combination: "Address: Street, house, building, apartment"
       ,hr_division: "HR Division"
       ,hr_it_num: "IT-accreditation number"
       ,hr_it_start_date: "IT-accreditation date"
       ,subpoena_receipt_date: "Subpoena receipt date"
       ,subpoena_seria_num: "Subpoena seria num"
       ,subpoena_appearence_date_time: "Appearence date time"
       ,subpoena_military_office: "Military office"
       ,subpoena_military_office_address: "Military office address"
       ,subpoena_type: "Subpoena type"
       ,subpoena_reason: "Subpoena reason"
    }
};

// нужно в начале сеанса подготовить "Список полей" [ { id, name }, ... ] для компонента в Админке,
// в Админке пока используется только русский язык
export const hrPersonsFieldsList = Object.entries( captions[ "ru" ] ).map( e => ({ id: e[0], name: e[1] }) );

const emptyRecord = { ...captions[ "ru" ] };
for( const key in emptyRecord ) {
    emptyRecord[ key ] = '';
};


export function setHrPersonsFields(
    dispatch
   ,hr_login = undefined
   ,hr_business_group_name = undefined
   ,hr_assignment_number = undefined
   ,hr_loan_number = undefined
   ,subpoena_receipt_date = undefined
   ,suffix = undefined
) {
    const tail = suffix === undefined || suffix === null ? '' : suffix;
    let state = {};
    
    // изменилось поле Логин или Номер назначения в компоненте "HR Справочник cотрудников",
    // нужно установить зависимые поля - "ФИО", "Паспорт" и т.д. из справочника hrPersons

    const optionsList = dispatch( ( dispatch, getState ) => getState().doc.hrPersons );
    const optionName = "hr_login";

    if( hr_login === undefined ) {
        if( !Array.isArray( optionsList ) || optionsList.length < 1 ) return;
        hr_login = optionsList[0].hr_login;
        dispatch( setDocStatePropValue( "hr_login",  hr_login ) );
        lastRequestParam = hr_login;
    }
    
    // отбор лица по логину
    let recordList = getOptionsListRecords( optionsList, optionName, hr_login );
    if( !Array.isArray( recordList ) ) return;
    
    if( hr_business_group_name === undefined && recordList.length > 0 )
        hr_business_group_name = recordList[0].hr_business_group_name;
    
    // Business groups
    const businessGroupList = recordList.map( i => i.hr_business_group_name );
    state[ "businessGroupList" + tail ] = businessGroupList;


    // если несколько записей по логину, делаем отбор по бизнес-группе
    if( recordList.length > 1 )
        recordList = getOptionsListRecords( recordList, "hr_business_group_name", hr_business_group_name );

    if( !Array.isArray( recordList ) ) return;
    //TODO const record = recordList.length > 0 ? recordList[0] : {}; так вроде правильно, но не очищаются поля, если нажать крест на login select
    const record = recordList[0];


    // если assignmentRecord или loanRecord пустой, нужно очистить их поля,
    // поэтому сначала обнуляем все поля
    copyProps( state, emptyRecord, tail );

    // можно копировать не весь record, а только поля, нужные для заполнения шаблона, по списку captions
    copyProps( state, record, tail );
    
    
    // Assignments

    const hr_assignments = getOptionsRecordProp( record, "hr_assignments" );
    if( Array.isArray( hr_assignments ) ) {

        if( hr_assignment_number === undefined ) {
            hr_assignment_number = hr_assignments.length > 0 ? hr_assignments[0].hr_assignment_number : null;
        }
        // задвоение назначений не должно быть, но могло быть в процессе добавления БГ, если данные без БГ не удаляли
        const assignmentsList  = hr_assignments.length === 0 ? '' : makeUnique( hr_assignments.map( (item) => item.hr_assignment_number ) );
        const assignmentRecord = hr_assignments.length === 0 ? '' : hr_assignments.find( item => item[ "hr_assignment_number" ] === hr_assignment_number );
        
        copyProps( state, assignmentRecord, tail );
        state[ "assignmentsList" + tail ] = assignmentsList;
    }
    
    // Loans
    
    let hr_loans = getOptionsRecordProp( record, "hr_loans" );
    if( Array.isArray( hr_loans ) ) {
        hr_loans = hr_loans.filter( item => item[ "hr_assignment_number" ] === hr_assignment_number );
    
        if( hr_loan_number === undefined ) {
            hr_loan_number = hr_loans.length > 0 ? hr_loans[0].hr_loan_number : null;
        }

        const loanList   = hr_loans.length === 0 ? '' : hr_loans.map( (item) => item.hr_loan_number );
        const loanRecord = hr_loans.length === 0 ? '' : hr_loans.find( item => item[ "hr_loan_number" ] === hr_loan_number );

        copyProps( state, loanRecord, tail );
        state[ "loanList" + tail ] = loanList;
    }
    
    // Subpoenas

    const hr_subpoenas = getOptionsRecordProp( record, "hr_subpoenas" );
    if( Array.isArray( hr_subpoenas ) ) {

        if( subpoena_receipt_date === undefined ) {
            subpoena_receipt_date = hr_subpoenas.length > 0 ? hr_subpoenas[0].subpoena_receipt_date : null;
        }

        const subpoenaList   = hr_subpoenas.length === 0 ? '' : hr_subpoenas.map( (item) => item.subpoena_receipt_date );
        const subpoenaRecord = hr_subpoenas.length === 0 ? '' : hr_subpoenas.find( item => item[ "subpoena_receipt_date" ] === subpoena_receipt_date );

        copyProps( state, subpoenaRecord, tail );
        state[ "subpoenaList" + tail ] = subpoenaList;
    }
    
    dateParts( state ); // добавить поля _dd, _mm, _yyyy к полям date
    dispatch( setDocStatePropMap( state ) );
}


// 1) выбрать несколько человек, заполнить выпадающий список
let cancelRequest;
let lastRequestParam;
export function getHrPersonsDB( dispatch, value/*, init = false*/ ) {
 
    value = ( value === undefined || value === null ) ? '' : value;
    /*if( !init )*/ if( value === '' || value === lastRequestParam ) return;
    lastRequestParam = value;
    
    if( cancelRequest ) {
        cancelRequest.cancel();
    }
    cancelRequest = axios.CancelToken.source();

    get_common(
        APP_URL + '/api/hr/persons'
       ,dispatch
       ,caption.hrError + ': '
       ,data => {
            if( !Array.isArray( data ) ) return;
            dispatch( setPropValue( DOC, "hrPersons", data ) );
            // если это первая загрузка шаблона, заполнить поля формы данными HR для пользователя
            //if( init ) setHrPersonsFields( dispatch );
        }
       ,f=>f
       ,null
       ,{ params: { value }, cancelToken: cancelRequest.token, }
    );
}


// 2) загрузить всю информацию по одному лицу
export function getHrOnePersonDB( dispatch, value, limitToBusinessGroups, suffix = undefined ) {
 
    value = ( value === undefined || value === null ) ? '' : value;
    
    if( cancelRequest ) {
        cancelRequest.cancel();
    }
    cancelRequest = axios.CancelToken.source();

    get_common(
        APP_URL + '/api/hr/person'
       ,dispatch
       ,caption.hrError + ': '
       ,data => {
            if( !Array.isArray( data ) ) return;
            
            // Только выбранные в Админке бизнес-группы (если пусто - без ограничения)
            if( Array.isArray( limitToBusinessGroups ) && limitToBusinessGroups.length > 0 )
                data = data.filter( i => limitToBusinessGroups.includes( i.hr_business_group_id ) );

            dispatch( setPropValue( DOC, "hrPersons", data ) );
            // заполнить поля формы данными HR для пользователя
            setHrPersonsFields(
                        dispatch
                       ,value === '' ? undefined : value
                       ,undefined
                       ,undefined
                       ,undefined
                       ,undefined
                       ,suffix
                    );
        }
       ,f=>f
       ,null
       ,{ params: { value }, cancelToken: cancelRequest.token, }
    );
}
