import { connect } from 'react-redux'
import { default as VApp } from './../components/App/App'
import React, { Component } from 'react'
import { withSnackbar } from 'notistack'; // for enqueueSnackbar
import { ValidatorForm } from 'react-material-ui-form-validator';
import isValidDate from 'date-fns/isValid';
import {
    setMessage
   ,setPropValue
} from '../redux/actions/utils';
import {
    getDocNames
   ,getCdocProperties
} from '../redux/actions/admin';
import {
    loadYaOrgs
   ,loadYaBusinessGroups
} from '../redux/actions/yandex';
import {
    getExtUserIdFromAddressBar
} from '../redux/actions/ext';
import {
    initSnackbar
} from '../redux/actions/snackbar';
import {
    getCopy
   ,getLang
} from '../redux/actions/localStorage';
import {
    setGlobalLang
} from '../redux/actions/lang';
import {
    SESSION
} from "../redux/constants";

let mounted = false;

class App extends Component {

    componentDidMount() {
        !mounted && this.props.onInit( this.props );
        mounted = true;
    }
    
    componentWillUnmount() {
        // remove rule when it is not needed
        ValidatorForm.removeValidationRule( 'noSpaces' );
        ValidatorForm.removeValidationRule( 'tag' );
        ValidatorForm.removeValidationRule( 'money' );
        ValidatorForm.removeValidationRule( 'date' );
    }
    
    render() {
        return (
            <VApp
                { ...this.props }
            />
        )
    }
}

const mapStateToProps = ( { modal, user, session, doc } ) => {
    return {
        modal
       ,user
       ,lang: session.lang
       ,showPreview: doc.showPreview
    }
}

const mapDispatchToProps = dispatch => ({
    dispatch // для переключателя Lang в Header
   ,onInit: ( props ) => {
     
        initSnackbar( props.enqueueSnackbar, props.closeSnackbar );
        
        // custom rules
        // common rules are in node_modules\react-form-validator-core\lib\ValidationRules.js
        ValidatorForm.addValidationRule( 'noSpaces', value => !value.match( /\s/g ) );
        ValidatorForm.addValidationRule( 'tag', value => !value.match( /[{}<>]/g ) ); // no brackets in tags
        // "money" is like "isFloat", but can have decimal dot in the end - "123,"
        ValidatorForm.addValidationRule( 'money', value => value.match( /^(?:-?[1-9]\d*|-?0)?(?:\.\d*)?$/i ) );
        // date validation, empty is valid
        ValidatorForm.addValidationRule( 'date', value => !value || isValidDate( value ) );

        const lang = getLang();
        setGlobalLang( lang );
        dispatch( setPropValue( SESSION, 'lang', lang ) );

        // скопированные поля сохраняются между сессиями
        const copy = getCopy();
        dispatch( setPropValue( SESSION, 'copy', copy ) );
        
        getExtUserIdFromAddressBar( dispatch ); // для внешнией формы - получить uuid из адресной строки
        getDocNames( dispatch );
        getCdocProperties( dispatch );
        
        // загрузить общие списки из базы,
        // хотел перенести в actions/admin.js/loadListsDB(), загрузка списков по необходимости,
        // но ещё нужно учесть случай, когда в шаблон добавляется компонента в Админке - списки нужны до создания первого шаблона
        loadYaOrgs( dispatch );
        // setTimeout, чтобы быстрее начало работать приложение, а зарузка пошла позже
        setTimeout( () => loadYaBusinessGroups( dispatch ), 3000 );
        // loadStartrekFields( dispatch ); // перенёс в onClick по списку "Поле трекера"
        
    }
   ,onCancel: () => {
        dispatch( setMessage( null ) )
    }
})

export default connect( mapStateToProps, mapDispatchToProps )( withSnackbar( App ) )
