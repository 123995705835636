// язык хранится:
// 1) в React state session.lang - используется в компонентах, а главное, изменение state запускает перерисовку,
// 2) и здесь, в глобальной переменной - для функций из actions/admin.js и подобной рассыпухи, здесь же хранятся переводы для этих функций

export let gLang = "ru";
export const langList = [ "en", "ru" ]; // список доступных языков
export const mainLang = "ru"; // основной язык
export let isLangEn = false; // хранить перевод на английский в шаблоне

export function setLangEn( value ) {
    isLangEn = value;
}

const docPartsCaptions = {
    "ru": {
        defaultValue: "Значение по умолчанию"
       ,dateFormatError: "Ошибка в формате даты"
       ,selectType: "Тип - Выпадающий список"
        
    }
    ,"en": {
        defaultValue: "Значение по умолчанию"
       ,dateFormatError: "Date format error"
       ,selectType: "Type - Drop-down list"
    }
};

const captions = {
    "ru": {
        // DocFormContent.jsx
        expiredUrl: "Ссылка неактивна. Запросите новую ссылку."
       ,copyURL: "Ссылка на шаблон"
       ,redoFillOut: "Заполнить как в прошлый раз"
       ,clearForm: "Очистить форму"
       ,formAccess: "За доступом обратитесь к владельцам шаблона"
        // Preview.jsx
       ,showPreview: "Предпросмотр документа"
       ,hidePreview: "Закрыть предпросмотр документа"
        // admin.js
       ,group: "Группа"
       ,doc: "Шаблон"
       ,docCode: "Код шаблона"
       ,urlCopy: "Ссылка на шаблон скопирована в буфер обмена"
       ,formSaved: "Форма сохранена"
       ,formSaveError: "Ошибка сохранения формы"
       ,saveAdminPasswordError: "Ошибка сохранения пароля для редактирования документа"
       ,templateLoadError: "Ошибка загрузки шаблона"
       ,predefinedLoadError: "Ошибка загрузки данных шаблона"
       ,extdefinedLoadError: "Ошибка загрузки данных внешней формы"
       ,extprotectedLoadError: "Ошибка загрузки защищённых данных внешней формы"
       ,loadAdminPasswordError: "Ошибка загрузки пароля для редактирования документа"
       ,formReloaded: "Форма обновлена из базы"
       ,loadAppPropsError: "Ошибка при получении свойств приложения"
       ,loadSuperusersError: "Ошибка при получении списка суперпользователей"
       ,loadDepartmentsError: "Ошибка при получении списка подразделений пользователя"
       ,loadTepmlateListError: "Ошибка при получении списка шаблонов"
       ,loadGroupListError: "Ошибка при получении списка групп"
       ,templateUploadError: "Ошибка отправки шаблона"
       ,templateSaved: "Шаблон сохранён"
       ,dataIsSent: "Спасибо, данные отправлены"
       ,ticketCreationError: "Ошибка при создании тикета"
       ,docQueueAllowed: "Документ можно добавить только в тикет очереди"
       ,attachError: "Ошибка добавления документа в тикет"
       ,trackerFieldsError: "Ошибка при загрузке полей Трекера"
       ,docName: "Название шаблона"
       ,CREATED_TICKET: "Создан тикет"
       ,ATTACHED_TO_TICKET: "Документ добавлен в тикет"
       ,tagError: "Некорректная конструкция тега"
       ,tagErrorLine: "Номер строки"
       ,tagConditionError: "Некорректная конструкция условия if"
       ,tagConditionEqualError: "Некорректная конструкция условия if. Ожидается два знака =="
       ,tagConditionEndIfError: "Некорректная конструкция условия if. Ожидается {%endif%}"
       ,tagUnknownError: "Присутствует нераспознаваемое выражение"
        // doc.js
       ,templateError: "Ошибка. Провалидируйте и загрузите файл шаблона."
        // groupEditor.js
       ,addGroupError: "Ошибка добавления группы"
       ,deleteGroupError: "Ошибка удаления группы"
       ,saveGroupError: "Ошибка сохранения группы"
        // hrPersons.js
       ,hrError: "Ошибка при получении данных HR о лицах"
        // role.js
       ,empListError: "Ошибка при получении списка сотрудников"
       ,depListError: "Ошибка при получении списка подразделений"
       ,addRoleError: "Ошибка добавления роли"
       ,delRoleError: "Ошибка удаления роли"
       ,roleListError: "Ошибка при получении списка ролей"
        // templateEditor.js
       ,addTemplateError: "Ошибка добавления шаблона"
       ,copyTemplateError: "Ошибка копирования шаблона"
       ,delTemplateError: "Ошибка удаления шаблона"
       ,saveTemplateError: "Ошибка сохранения шаблона"
        // uploads.js
       ,massTemplate: "Шаблон для масс-формирования документов"
       ,massLoadError: "Ошибка обработки файла"
       ,massResultError: "Ошибка при получении данных"
       ,fileSaved: "Файл сохранён"
       ,uploadStatusError: "Ошибка при смене статуса загрузки"
        // utils.js
       ,tokenError: "Ошибка при получении CSRF-токена"
        // vendorData.js
       ,vendorDataError: "Ошибка при получении данных о контрагенте"
       ,vendorListError: "Ошибка при получении списка контрагентов"
        // yandex.js
       ,yandexOrgError: "Ошибка при получении данных о компании" // "Яндекс" убрали для анонимности на внешних формах, CDOC-116
       ,yandexListError: "Ошибка при получении списка юрлиц"
       ,yandexBgError: "Ошибка при получении списка бизнес-групп"
    }
    ,"en": {
        // DocFormContent.jsx
        expiredUrl: "URL is expired. Request a new link."
       ,copyURL: "Copy URL of this form"
       ,redoFillOut: "Redo last fill out"
       ,clearForm: "Clear the form"
       ,formAccess: "Please contact the form owners for access"
        // Preview.jsx
       ,showPreview: "Document preview"
       ,hidePreview: "Close document preview"
        // admin.js
       ,group: "Group"
       ,doc: "Form"
       ,docCode: "Form code"
       ,urlCopy: "URL is in the clipboard"
       ,formSaved: "Form is saved"
       ,formSaveError: "Form saving error"
       ,saveAdminPasswordError: "Error saving password for document editing"
       ,templateLoadError: "Form loading error"
       ,predefinedLoadError: "Form data loading error"
       ,extdefinedLoadError: "External form data loading error"
       ,extprotectedLoadError: "External form protected data loading error"
       ,loadAdminPasswordError: "Error loading password for document editing"
       ,formReloaded: "Form is reloaded"
       ,loadAppPropsError: "Error loading application properties"
       ,loadSuperusersError: "Error getting list of superusers"
       ,loadDepartmentsError: "Error getting list of person departments"
       ,loadTepmlateListError: "Error getting list of forms"
       ,loadGroupListError: "Error getting list of groups"
       ,templateUploadError: "Template upload error"
       ,templateSaved: "Template is saved"
       ,dataIsSent: "Thanks, the data has been sent"
       ,ticketCreationError: "Error when creating a ticket"
       ,docQueueAllowed: "Document can only be added to a ticket in the queue"
       ,attachError: "Error adding document to ticket"
       ,trackerFieldsError: "Error loading Tracker fields"
       ,docName: "Form name"
       ,CREATED_TICKET: "Ticket created:"
       ,ATTACHED_TO_TICKET: "Document added to ticket"
       ,tagError: "Incorrect tag construction"
       ,tagErrorLine: "Line number"
       ,tagConditionError: "Incorrect construction of the condition IF"
       ,tagConditionEqualError: "Incorrect construction of the condition IF. Two == signs are expected."
       ,tagConditionEndIfError: "Incorrect construction of the condition IF. Expected {%endif%}"
       ,tagUnknownError: "Unrecognizable expression"
        // doc.js
       ,templateError: "Error. Validate and upload the template file."
        // groupEditor.js
       ,addGroupError: "Error adding group"
       ,deleteGroupError: "Group deletion error"
       ,saveGroupError: "Error saving group"
        // hrPersons.js
       ,hrError: "Error when getting HR data"
        // role.js
       ,empListError: "Error getting list of employees"
       ,depListError: "Error getting list of departments"
       ,addRoleError: "Error adding role"
       ,delRoleError: "Role deletion error"
       ,roleListError: "Error getting list of roles"
        // templateEditor.js
       ,addTemplateError: "Error adding form"
       ,copyTemplateError: "Form copy error"
       ,delTemplateError: "Form deletion error"
       ,saveTemplateError: "Form saving error"
        // uploads.js
       ,massTemplate: "Template for mass generation of documents"
       ,massLoadError: "File processing error"
       ,massResultError: "Error while getting data"
       ,fileSaved: "File saved"
       ,uploadStatusError: "Error when changing upload status"
        // utils.js
       ,tokenError: "Error while getting CSRF token"
        // vendorData.js
       ,vendorDataError: "Error when getting counterparty data"
       ,vendorListError: "Error when getting counterparty list"
        // yandex.js
       ,yandexOrgError: "Error when getting data about company"
       ,yandexListError: "Error when getting a list of legal entities"
       ,yandexBgError: "Error when getting a list of business groups"
    }
};

const yesNoCaptions = {
    "ru": {
        no: "Нет"
       ,yes: "Да"
    }
    ,"en": {
        no: "No"
       ,yes: "Yes"
    }
};

export function setGlobalLang( value ) {
    const lang = value ? value : "ru";
    gLang = lang;
    caption = captions[ lang ] ?? captions[ "ru" ];
    docPartsCaption = docPartsCaptions[ lang ] ?? docPartsCaptions[ "ru" ];
    yesNoCaption = yesNoCaptions[ lang ] ?? yesNoCaptions[ "ru" ];
}

export let caption = captions.ru;
export let docPartsCaption = docPartsCaptions.ru;
export let yesNoCaption = yesNoCaptions.ru;
export let yesNoList = // TODO docParts создаётся в начале сеанса и не меняется, поэтому в компонентах будет только русский пока
[
    { id: false, name: yesNoCaption.no } // true, false - boolean для Checkbox, но для редактируемых полей нужно преобразовать в строку
   ,{ id: true, name:yesNoCaption.yes }
];
