import React from 'react';
import { List, arrayMove } from 'react-movable';
import styles from "./MovableTable.module.css"
import ConfirmationPopup from "./ConfirmationPopup";
import { TextValidator } from 'react-material-ui-form-validator';
import { makeStyles } from '@material-ui/core/styles';
import {
    setRowHeight
} from "../../../redux/actions/table";


const useStyles = makeStyles({
    root: {
        "& .MuiInput-root": {
            lineHeight: "1.2"
           ,padding: "7px"
        }
    }
});


const MovableTable = ( props ) => { 
    
    const classes = useStyles();
    
    const headerList = props.header;
    const name = props.name;
    const readonly = props.readonly;

    const headCol = ( item, idx ) =>
        <div key = { idx } className = { styles.head }>
        <TextValidator
            multiline
            style = {{ width: "100%" }}
            key  = { name + '$' + idx }
            name = { name + '$' + idx }
            value = { item }
            size = "small"
            placeholder = ""
            className = { classes.root }
            inputProps = {{ style: { textAlign: "center", verticalAlign: "middle", fontSize: "small", overflow: "hidden" }, readOnly: true }}
            InputProps = {{ disableUnderline: true }}
        />
        </div>;
        
    const size = headerList.length;
    const row = ( i, idx ) => <div key = { idx } className = { styles.row }>{ i }</div>;
    const col = ( i, idx ) => <div key = { idx } className = { idx < 1 || idx > size ? styles.col : styles.border }>{ i }</div>;
    const header = headerList.map( ( item, idx ) => headCol( item, idx ) );

    const items = props.items.map( ( i, rid ) => row(
        i.map( ( j, cid ) => col( j, cid ) )
       ,rid
    ));

    
    const deleteAllButton = readonly ? <div style={{ padding: '4px' }} ></div> :
        <ConfirmationPopup
            onClick = { props.onDeleteAll }
        />;
        
        
    // установить высоту шапки в соответствии с количеством строк текста
    setRowHeight( headerList, name );
    
    
    return (
        <div className = { styles.table }>
            <div className = { styles.row }>
                <div className = { styles.col }></div>
                { header }
                <div className = { styles.col }>{ deleteAllButton }</div>
            </div>
            
            <List
                values = { items }
                onChange = { ({ oldIndex, newIndex }) =>
                    props.onOrderChange( arrayMove( props.value, oldIndex, newIndex ), oldIndex, newIndex )
                }
                renderList = { ({ children, props }) => <div key = { "key" } className = { styles.tbody } { ...props }>{ children }</div> }
                renderItem = { ({ value, props, isDragged }) =>
                    isDragged ?
                    (
                        <div className = { styles.table } { ...props }>
                         { value }
                        </div>
                    )
                    :
                    value
                }
            />
        </div>
    );
};

export default MovableTable;
